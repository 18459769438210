<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" fill="#18246f" stroke-width="0px">
    <path
      class="cls-1"
      d="M56.99365,57.4502h-11.82422c-1.10449,0-2-.89551-2-2v-16.00244c-.01758-.81201-.36279-2.75928-2.80078-2.72314-2.52002.0249-2.65723,2.27979-2.65918,2.73193v15.99365c0,1.10449-.89551,2-2,2h-11.27246c-1.10449,0-2-.89551-2-2v-30.74219c0-1.10449.89551-2,2-2h11.27246c1.03906,0,1.89258.79199,1.99072,1.80518,1.85254-.93506,4.39551-1.80518,7.44531-1.80518,3.87207,0,7.0415,1.26123,9.41992,3.74805,4.74316,4.95898,4.44141,13.00781,4.42627,13.34766l.00195,15.64648c0,1.10449-.89551,2-2,2ZM47.16943,53.4502h7.82422v-13.73535c.00439-.15186.23096-6.79688-3.33008-10.50586-1.61621-1.68311-3.74805-2.50098-6.51807-2.50098-4.81055,0-8.03613,2.94092-8.06787,2.9707-.5835.54102-1.43555.68701-2.16406.37061-.729-.31836-1.2041-1.03418-1.2041-1.82959v-1.51172h-7.27246v26.74219h7.27246v-14.00244c.01123-2.68164,1.78369-6.67529,6.61963-6.72314.02979,0,.06006-.00049.08984-.00049,2.4165,0,3.91064.97363,4.74219,1.7959,1.95068,1.93066,2.0083,4.62598,2.0083,4.92676v14.00342Z"
    />
    <path
      class="cls-1"
      d="M17.87451,57.4502H7.15381c-1.10449,0-2-.89551-2-2v-30.74268c0-1.10449.89551-2,2-2h10.7207c1.10449,0,2,.89551,2,2v30.74268c0,1.10449-.89551,2-2,2ZM9.15381,53.4502h6.7207v-26.74268h-6.7207v26.74268Z"
    />
    <path
      class="cls-1"
      d="M12.51123,21.57227c-4.1416,0-7.51123-3.36963-7.51123-7.51123s3.36963-7.51123,7.51123-7.51123c4.14209,0,7.51172,3.36963,7.51172,7.51123s-3.36963,7.51123-7.51172,7.51123ZM12.51123,10.5498c-1.93604,0-3.51123,1.5752-3.51123,3.51123s1.5752,3.51123,3.51123,3.51123c1.93652,0,3.51172-1.5752,3.51172-3.51123s-1.5752-3.51123-3.51172-3.51123Z"
    />
  </svg>
</template>
